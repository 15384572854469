<template>
  <miscellaneous>
    <span class="icon-page-misc mb-2">
      <feather-icon
        icon="SendIcon"
      />
    </span>

    <div class="text-center mb-1">
      <h2 class="text-center">
        Seu e-mail ainda não foi verificado!
      </h2>
      <p class="text-center">
        Verifique a sua caixa de spam ou lixo eletrônico.<br>
        Se necessário entre em contato com o
        <b-link :to="{ name: supportRouter.name }">
          suporte.
        </b-link>
      </p>
    </div>

    <button-form
      @action="handleRedirectToLogin"
    >
      Ir para o início!
    </button-form>
  </miscellaneous>
</template>

<script>
import Miscellaneous from '@/components/Miscellaneous/Miscellaneous.vue'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import { required, email } from '@/utils/validations/validations'
import { supportRouter } from '@/router/routePathName'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'UnverifiedEmail',

  components: {
    Miscellaneous,
    ButtonForm,
    BLink,
  },

  data() {
    return {
      supportRouter,
      required,
      email,

      emailInStore: this.$store.state.chooseData.unverifiedEmail,
    }
  },

  methods: {
    handleRedirectToLogin() {
      this.$router.replace({ name: 'home' })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/page-misc';
</style>
