export const messages = {
  requiredField: 'Campo obrigatório.',
  invalidEmail: 'E-mail inválido.',
  invalidCPF: 'CPF inválido.',
  invalidCNPJ: 'CNPJ inválido.',
  CNPJAlreadyExists: 'Este CNPJ já está cadastrado em nosso sistema.',
  invalidPhone: 'Telefone inválido.',
  invalidPassword: 'A senha digitada não está de acordo com as regras acima.',
  invalidPasswordConfirmation: 'O campo confirmação de senha deve ser igual ao campo senha.',
  invalidCode: 'Código inválido.',

  successSave: 'Dados salvos com sucesso.',
  successSendMessage: 'Mensagem enviada!',
  termsOfUseWarning: 'Você precisa concordar com os Termos de Uso e Políticas de Privacidade para continuar!',
  successSendMessageInfo: 'Em breve entraremos em contato com você, se necessário, para verificarmos a questão.',
  errorUploadImage: 'Não foi possível realizar o upload da imagem para este registro, caso o erro persista, entre em contato com o suporte.',
  successDelete: 'Registro excluído com sucesso.',
  impossible: 'Não foi possível realizar a sua solicitação no momento.</br> Caso o erro persista, contate o suporte.',
  error: 'Não foi possível realizar a sua solicitação, contate o suporte',
  invalidTokenForgotPassword: 'Token de verificação inválido, por favor solicite uma nova troca de senha!',
  tokenForgotPasswordNotFound: 'Token de verificação não encontrado!',
  productNotFound: 'Produto não encontrado!',
  addFavoriteProducts: 'Produto adicionado aos favoritos.',
  removeFavoriteProducts: 'Produto removido dos favoritos.',

  confirmDelete: 'Deseja excluir este registro ?',
  confirmGenerateNewPassword: 'Tem certeza que deseja gerar uma nova senha para este usuário ?',

  countField: value => `Este campo deve conter no mínimo ${value} caracteres.`,
}
