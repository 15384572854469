<template>
  <div class="custom-misc">
    <b-row class="w-100 m-0">
      <b-col
        lg="12"
        class="d-flex justify-content-center"
      >
        <div class="page-misc-view">
          <img
            :src="logoImg"
            alt="Lalak"
          >

          <div class="page-misc-card">
            <slot />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import logoImg from '../../assets/img/logo/lalak-logo.png'

export default {
  name: 'Miscellaneous',

  components: {
    BRow,
    BCol,
  },

  data() {
    return {
      logoImg,
    }
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/page-misc';
</style>
